import { takeLatest, call, put } from "redux-saga/effects";
import { pathObj } from "../../../services/apiPath";
import { apiGet, apiPost } from "services/httpServices";
import { successToast } from "utils/toastMessage";

function* fetchRewardHistory(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.REWARD_HISTORY,
      action.payload
    );
    if (response.status == 200) {
      yield put({
        type: "SET_REWARD_HISTORY",
        payload: response?.data?.data,
      });
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_REWARD_HISTORY",
        payload: {},
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_REWARD_HISTORY",
      payload: {},
    });
    console.log(error);
  }
}
function* fetchRewardAmount(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(apiPost, pathObj.REWARD_AMOUNT, action.payload);
    if (response.status == 200) {
      yield put({
        type: "SET_REDEEM_AMOUNT",
        payload: response?.data?.data,
      });
      // callback && callback(false, false);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_REDEEM_AMOUNT",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_REDEEM_AMOUNT",
      payload: [],
    });
    console.log(error);
  }
}
function* fetchRewardAllCard(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.REWARD_ALL_CARD,
      action.payload
    );
    if (response.status == 200) {
      yield put({
        type: "SET_REDEEM_ALL_CARD",
        payload: response?.data?.data,
      });
      callback && callback(response?.data?.data, false, false);
    } else {
      callback && callback(null, false, true);
      yield put({
        type: "SET_REDEEM_ALL_CARD",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, false, true);
    yield put({
      type: "SET_REDEEM_ALL_CARD",
      payload: [],
    });
    console.log(error);
  }
}

function* handelAddUpdateRewardCard(action) {
  let { callback } = action;
  callback && callback(null, null, true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.ADD_UPDATE_REWARD_CARD,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response.data.message);
      callback && callback(response?.data?.data, null, false, false);
    } else {
      callback && callback(null, null, false, true);

      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, error?.response?.data, false, true);
    console.log(error);
  }
}

function* handelRewardPlaceOrder(action) {
  let { callback } = action;
  callback && callback(true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.REWARD_PLACE_ORDER,
      action.payload
    );
    if (response.status == 200) {
      console.log("RESPONSE OF HANDEL PLACE ORDER", response.data);
      yield put({
        type: "SET_PLACE_ORDER",
        payload: response?.data,
      });
      yield put({
        type: "SHOW_PLACE_ORDER_SUCCESS_UI",
        payload: true,
      });
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_PLACE_ORDER",
        payload: {},
      });
      yield put({
        type: "SHOW_PLACE_ORDER_SUCCESS_UI",
        payload: false,
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, error?.response?.data?.error);
    yield put({
      type: "SET_PLACE_ORDER",
      payload: {},
    });
    yield put({
      type: "SHOW_PLACE_ORDER_SUCCESS_UI",
      payload: false,
    });
    console.log("err", error.data);
  }
}

export function* RewardsWatcher() {
  yield takeLatest("GET_REWARD_HISTORY", fetchRewardHistory);
  yield takeLatest("GET_REDEEM_AMOUNT", fetchRewardAmount);
  yield takeLatest("GET_REDEEM_ALL_CARD", fetchRewardAllCard);
  yield takeLatest("GET_ADD_UPDATE_CARD_TO_CART", handelAddUpdateRewardCard);
  yield takeLatest("GET_REWARD_PLACE_ORDER", handelRewardPlaceOrder);
}
